<template>
  <div class="container mt-3">
    <div v-if="loggedIn">
      <h4 class="mb-3">Set new password</h4>
      <form @submit.prevent="setPassword()">
        <div class="mb-3">
          <label class="form-label" for="password">New password</label>
          <input class="form-control" type="password" name="password" id="password" v-model="password" required>
        </div>
        <div class="mb-3">
          <label class="form-label" for="password2">Re-type password</label>
          <input class="form-control" type="password" name="password2" id="password2" @change="passwordMatch($event)" required>
        </div>
        <button class="btn btn-primary" type="submit">Continue</button>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      password: '',
      loggedIn: false
    }
  },
  async mounted () {
    const guardianId = this.$route.params.guardianId
    const token = this.$route.params.token
    if (!Array.isArray(guardianId) && !Array.isArray(token)) {
      const response = await this.$api.tokenLogin(parseInt(guardianId), token)
      if (response.guardian) {
        this.loggedIn = true
      }
    }
  },
  methods: {
    passwordMatch (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        if (target.value === this.password) {
          target.setCustomValidity('')
        } else {
          target.setCustomValidity('Password does not match.')
        }
      }
    },
    async setPassword () {
      const response = await this.$api.setPassword(this.password)
      if (response.success) {
        this.$router.replace({ name: 'home' })
      }
    }
  }
})
</script>

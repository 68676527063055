
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      password: '',
      loggedIn: false
    }
  },
  async mounted () {
    const guardianId = this.$route.params.guardianId
    const token = this.$route.params.token
    if (!Array.isArray(guardianId) && !Array.isArray(token)) {
      const response = await this.$api.tokenLogin(parseInt(guardianId), token)
      if (response.guardian) {
        this.loggedIn = true
      }
    }
  },
  methods: {
    passwordMatch (event: Event) {
      const target = event.target
      if (target instanceof HTMLInputElement) {
        if (target.value === this.password) {
          target.setCustomValidity('')
        } else {
          target.setCustomValidity('Password does not match.')
        }
      }
    },
    async setPassword () {
      const response = await this.$api.setPassword(this.password)
      if (response.success) {
        this.$router.replace({ name: 'home' })
      }
    }
  }
})
